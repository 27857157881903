import Vue from "vue";
import Router from "vue-router";
import store from "../store";

/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */

Vue.use(Router);

let router = new Router({
  routes: [
    {
      path: "/login",
      name: "login",
      meta: { auth: false },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "login" */ "@/pages/Login.vue")
    },
    {
      path: "/",
      name: "home",
      meta: { auth: true },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "home" */ "@/pages/Home.vue")
    },
    {
      path: "/user/:id",
      name: "user-detail",
      meta: { auth: true },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "home" */ "@/pages/UserDetail.vue")
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth === true) {
    if (!store.getters["auth/isAuthenticated"]) {
      next({ name: "login" });
      return;
    }
  }

  if (to.name === "login") {
    if (store.getters["auth/isAuthenticated"]) {
      next({ name: "home" });
      return;
    }
  }
  next();
});

export default router;
