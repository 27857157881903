// import { vuexfireMutations, firestoreAction } from "vuexfire";
// import createPersistedState from "vuex-persistedstate";
import { auth } from "@/plugins/firebase";
import router from "@/plugins/router";
import { db } from "@/plugins/firebase";
// const axios = require("axios");

/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */

const state = {
  authenticated: false,
  user: null,
  loginError: "",
};

const getters = {
  isAuthenticated: (state) => state.authenticated,
  loginError: (state) => state.loginError,
  getUser: (state) => state.user,
};

const actions = {
  login({ commit }, { email, password }) {
    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        var docRef = db.collection("users").doc(userCredential.user.uid);
        return docRef.get();
      })
      .then((doc) => {
        let loggedIn = false;
        if (doc.exists) {
          let userData = doc.data();
          if (userData.roles && userData.roles.includes("admin")) {
            commit("setAuthenticated", true);
            commit("setUser", auth.currenUser);
            commit("setLoginError", "");
            loggedIn = true;
            router.go({ name: "home" });
          }
        }
        if (!loggedIn) {
          commit("setLoggedOut");
          commit(
            "setLoginError",
            "Bei der Anmeldung ist ein Fehler aufgetreten"
          );
        }
        return;
      })
      .catch(() => {
        // this.dispatch("unbindUser");
        // console.error("login error", e);
        commit("setLoginError", "Bei der Anmeldung ist ein Fehler aufgetreten");
        commit("setLoggedOut");
        return false;
      });
  },
  logout({ commit }) {
    auth
      .signOut()
      .then(() => {
        // this.dispatch("unbindUser");
        commit("setLoggedOut");
        document.cookie = "__session=";
      })
      .catch(() => {
        // this.dispatch("unbindUser");
        commit("setLoggedOut");
      })
      .then(() => {
        router.go({ name: "home" });
      });
  },
};

// mutations
const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setUserAdditional(state, additional) {
    state.additionalUserInfo = additional;
  },
  setAuthenticated(state, authenticated) {
    state.authenticated = authenticated;
  },
  setLoginError(state, error) {
    state.loginError = error;
  },
  setLoggedOut(state) {
    state.authenticated = false;
    state.user = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
