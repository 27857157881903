/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
import { db } from "@/plugins/firebase";
const state = {
  previous_results: [],
  result: null,
  search_in_progress: false,
  no_result: false
};

const getters = {
  getById: state => id => state.users.find(user => user.id === id),
  previousResults: state => state.previous_results,
  previousResultsByQuery: state => query =>
    query
      ? state.previous_results.filter(
          res => res.query && res.query.indexOf(query) >= 0
        )
      : [],
  result: state => state.result,
  searchInProgress: state => state.search_in_progress,
  noResult: state => state.no_result
};

const actions = {
  async resetSearch({ commit }) {
    commit("setSearchInProgress", false);
    commit("resetResult");
    commit("resetNoResult");
  },
  async search({ commit, dispatch }, { query }) {
    commit("setSearchInProgress", true);
    commit("resetResult");
    commit("resetNoResult");

    const results = [];
    console.log("search startet");
    // await new Promise(resolve => setTimeout(resolve, 7000));
    if (query !== "") {
      if (query.indexOf("@") >= 0) {
        //   console.log("email: " + query.indexOf("@"));
        await db
          .collection("users")
          .where("username", "==", query)
          .get()
          .then(snapshot => {
            if (snapshot.empty) {
              return;
            }

            snapshot.forEach(doc => {
              const user = { id: doc.id, data: doc.data(), query: query };
              results.push({ result: user });
              // console.log(doc.id, '=>', doc.data());
            });
          })
          .catch(err => {
            this.error = err;
            // console.log('Error getting documents', err);
          });
      } else {
        //   console.log("maybe document");
        await db
          .collection("users")
          .doc(query)
          .get()
          .then(doc => {
            if (doc.exists) {
              const user = { id: doc.id, data: doc.data(), query: query };
              results.push({ result: user });
            }
          });
      }
    }

    if (results.length < 1) {
      commit("setNoResult");
    } else {
      for (let index = 0; index < results.length; index++) {
        const element = results[index];
        dispatch("addResult", element);
      }
    }
    commit("setSearchInProgress", false);
    console.log("search ended");
  },
  addResult({ commit }, { result }) {
    console.log("addResult", result);
    commit("setResult", result);
    commit("addToLastResults", result);
  }
};

const mutations = {
  resetResult(state) {
    state.result = null;
  },
  setResult(state, result) {
    state.result = result;
  },
  addToLastResults(state, result) {
    const previous_results = state.previous_results;

    const new_results = [
      result,
      ...previous_results.filter(
        existingResult => existingResult.id !== result.id
      )
    ].splice(0, 20);
    state.previous_results = new_results;
    state.no_result = false;
  },
  setSearchInProgress(state, inProgress) {
    state.search_in_progress = inProgress;
  },
  setNoResult(state) {
    state.no_result = true;
  },
  resetNoResult(state) {
    state.no_result = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
