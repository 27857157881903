/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
// import { db } from "@/plugins/firebase";
import plans from "@/data/plans";
import { db, Timestamp } from "@/plugins/firebase";

const state = {
  plans: plans,
  saving: false,
};

const getters = {
  plans: (state) => state.plans,
};

const actions = {
  logPlans({ commit, state }) {
    // es
    commit("resetPlans");
    console.log(state.plans);
  },
  addCustomSubscription({ commit }, { subscription, uid }) {
    commit("saving", true);
    let current_period_end = Timestamp.fromDate(
      subscription.current_period_end.toDate()
    );
    let subscriptionData = {
      id: subscription.id,
      payment_provider: subscription.payment_provider,
      cancel_at_period_end: subscription.cancel_at_period_end,
      current_period_end: current_period_end,
      current_period_start: Timestamp.now(),
      device_count: subscription.device_count,
      price: subscription.price * 100,
    };

    var docRef = db.collection("users").doc(uid);
    docRef.set({ current_subscription: subscriptionData }, { merge: true });
    docRef.get().then(function(doc) {
      console.log(doc, subscription, subscriptionData);
      commit("saving", false);
    });
  },
};

const mutations = {
  resetPlans(state) {
    state.plans = plans;
  },
  saving(state, saving) {
    state.saving = saving;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
