import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import user from "./modules/user";
import search from "./modules/search";
import customSubscription from "./modules/customSubscription";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    auth,
    user,
    search,
    customSubscription,
  },
  strict: debug,
  //   plugins: debug ? [createLogger()] : []
  plugins: [
    createPersistedState({
      key: "eis-admin-v2",
      paths: ["auth", "search"],
    }),
  ],
});
