import * as Firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

let firebaseApp;

firebaseApp = Firebase.initializeApp({
  apiKey: "AIzaSyBy11ymeWuwSCuOKcmIcx68fHSbjh_jlwU",
  authDomain: "eis-app.firebaseapp.com",
  databaseURL: "https://eis-app.firebaseio.com",
  projectId: "eis-app",
  storageBucket: "eis-app.appspot.com",
  messagingSenderId: "227935359465",
  appId: "1:227935359465:web:db9d1d3c4d9d1782",
});

// Export the database for components to use.
// If you want to get fancy, use mixins or provide / inject to avoid redundant imports.
export const db = firebaseApp.firestore();
export const auth = Firebase.auth();
export const addUserAuth = Firebase.auth();
export const allowFirebaseChanges = true;
export const firebaseStorage = Firebase.storage();
export const Timestamp = Firebase.firestore.Timestamp;
// export const patchCollectionName: Map<string, string> = new Map([
//   ["words", "xwords"]
// ]);

export default firebaseApp;
