<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item>
            <v-list-item-title>
              <router-link class="button is-light" to="/">Home</router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>EiS Admin</v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="flex-grow-1 flex-shrink-0 mt-4">
        <SearchUserInput v-if="isAuthenticated === true" />
      </div>
      <v-spacer></v-spacer>
      <v-btn icon v-if="isAuthenticated === true" @click="logout()">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-content>
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
import SearchUserInput from "./components/SearchUserInput";
import { mapGetters } from "vuex";

export default {
  name: "App",

  components: {
    SearchUserInput
  },

  data: () => ({
    drawer: false
  }),
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated"
    })
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    }
  }
};
</script>
