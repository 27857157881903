/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
/* eslint no-unused-vars: ["error", { allow: ["log","warn", "error"] }] */
import { db } from "@/plugins/firebase";

const state = {
  users: [],
  user: {},
  error: null,
  saving: false,
};

const getters = {
  get: (state) => state.user,
  error: (state) => state.error,
};

const actions = {
  fetch({ commit }, { uid }) {
    var docRef = db.collection("users").doc(uid);
    commit("setError", null);
    docRef
      .get()
      .then(function(doc) {
        if (doc.exists) {
          //   console.log("Document data:", doc.data());
          commit("setUser", doc.data());
        } else {
          commit("setError", "Not found");
          // doc.data() will be undefined in this case
          //   console.log("No such document!");
        }
      })
      .catch(function(error) {
        commit("setError", error);
        // console.log("Error getting document:", error);
      });
  },
  addCustomSubscription({ commit, state }, { subscription }) {
    commit("saving", true);
    let uid = state.user.uid;

    var docRef = db.collection("users").doc(uid);
    docRef.get().then(function(doc) {
      console.log(doc, subscription);
      commit("saving", false);
    });
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
